import { AppBar, Paper, Typography } from "@mui/material";



export default function Header(props) {

    return (
        <AppBar
            // style={{display:"flex", justifyContent:"center", alignItems:"center"}}
            sx={{ marginBottom:'2%'}}
            position="fixed"
        >
            <Typography variant="h4" component="div">
                Create FOSTWIN Account
            </Typography>
        </AppBar>
    )
}