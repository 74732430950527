import './App.css';
import SignUp from './SignUp';
import Header from './Header';
import { CssBaseline } from '@mui/material';

function App() {
  return (
    <>
    <CssBaseline>
        <Header />
        <SignUp>
          
        </SignUp>
      </CssBaseline>
    </>
  );
}

export default App;
