import { Grid, Paper, AppBar, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {apiUrl} from './config';

import foswecImage from "./images/FOSWEC2_HWRL.png"





export default function SignUp() {

    const [errState, setErrState] = useState({
        email: false,
        emailText:"",
        firstName:false,
        firstNameText:"",
        lastName: false,
        lastNameText: "",
        submit: false,
        submitText: "",
        code: false,
        codeText: "",
    });
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");    
    // email code sent
    const [emailSent, setEmailSent] = useState(false);
    // code verified and user actually created
    const [codeVerified, setCodeVerified] = useState(false);
    // loading
    const [loading, setLoading] = useState(false);
    // user already had a code and came back to page
    const [hasCode, setHasCode] = useState(false);
    

    // post data to backend to create the user
    const postData = async (first, last, email, code) => {
        setLoading(true);
        
        let url = `${apiUrl}/createUser`;

        const res = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    email: email,
                    first: first,
                    last: last,
                    code: code,
                })
            }
        ).catch(err => {
            // console.error(err);
            setCodeVerified(false);
            setLoading(false);
            setErrState(prev => ({
                ...prev,
                code: true,
                codeText: "Unhandled Error, please check your code input and then contact administrator (graham@evergreeninnovations.co) if this persists."
            }));
        });
        if (res) {
            if (res.status !== 200) {
                const content = await res.text();
                // console.log("IN BAD STATUS - text: ", content);
                setErrState(prev => ({
                    ...prev,
                    code: true,
                    codeText: content,
                }));
                setLoading(false);
                return;
            } 
            setErrState(prev => ({
                ...prev,
                code: false,
                codeText: "",
            }));
            setCodeVerified(true);
        }
        setLoading(false);
        
    }

     // post data to backend to create the user
     // called when a user has a code but didn't input it right away
     const postDataWithCode = async (first, last, email, code) => {
        setLoading(true);
        let url = `${apiUrl}/createUser`;
        const res = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    email: email,
                    first: first,
                    last: last,
                    // times:s,
                    code: code,
                })
            }
        ).catch(err => {
            // console.error(err);
            setLoading(false);
            setErrState(prev => ({
                ...prev,
                code: true,
                codeText: "Unhandled Error, please check your code inputs and then contact administrator (graham@evergreeninnovations.co) if this persists."
            }));
        });
        if (res) {
            if (res.status !== 200) {
                let content = await res.text();
                if (content.trim() === 'Bad Code') {
                    content = "Bad code or email"
                }
                // console.log("IN BAD STATUS - text: ", content);
                setErrState(prev => ({
                    ...prev,
                    code: true,
                    codeText: content,
                }));
                setLoading(false);
                return;
            } 
            setErrState(prev => ({
                ...prev,
                code: false,
                codeText: "",
            }));
            // change the states to make it so they see the message about email for the login stuff
            setCodeVerified(true);
            setEmailSent(true);
        }
        setLoading(false);
        
    }

    // send request with user information to send them a code to verify their email
    const triggerCode = async (first, last, email) => {
        setLoading(true);
        const res = await fetch(`${apiUrl}/sendCode`,
        {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    email: email,
                    first: first,
                    last: last,
                    // times: s,
                })
            }
        ).catch(err => {
            
            setLoading(false);
            setErrState(prev => ({
                ...prev,
                submit: true,
                submitText:"Unhandled error, please check your inputs then contact admin if this persists. (graham@evergreeninnovations.co)"
            }));
        });
        if (res) {
            if (res.status !== 200) {
                const content = await res.text();
                // console.log("IN BAD STATUS FOR CODE - text: ", content);
                setErrState(prev => ({
                    ...prev,
                    submit: true,
                    submitText: content,
                }));
                setLoading(false);
                return;
            } 
            setErrState(prev => ({
                ...prev,
                submit: false,
                submitText: "",
            }))
            setEmailSent(true);
        }
        setLoading(false);
    }


    // handles first click and does input validation - first click is what sends the code
    const handleClick = (event) => {
        event.preventDefault();
        

        let f = first.trim();
        let l = last.trim();
        let e = email.trim();
        const errs = validate(f,l,e);
        if (errs.firstName || errs.lastName || errs.email) {
            setErrState(prev => ({
                ...prev,
                ...errs
            }));
            return;
        }
        // post request to backend to generate the code for the user - good response shows page for entering code from email
            triggerCode(f, l, e);
            setErrState(prev => ({
                ...prev,
                ...errs
            }));
       
    }
    // on click handler for confirming the code and actually creating account
    const handleConfirm = (event) => {
        event.preventDefault();
        let f = first.trim();
        let l = last.trim();
        let e = email.trim();
        let c = code.trim();
       
        postData(f, l, e, c);
    
    }

    // when user already has a code - they'll need to input their info again 
    // so we'll re-validate with a code validation too
    const handleConfirmWithCode = (event) => {
        event.preventDefault();
        let f = first.trim();
        let l = last.trim();
        let e = email.trim();
        let c = code.trim();
        const errs = validateWithCode(f, l, e, c);
        if (errs.firstName || errs.lastName || errs.email || errs.code) {
            setErrState(prev => ({
                ...prev,
                ...errs
            }));
            return;
        }
    
        postDataWithCode(f, l, e, c);
        setErrState(prev => ({
            ...prev,
            ...errs
        }));
    }

    const validate = (first, last, email) => {
        const errors = {
            email: false,
            emailText:"",
            firstName:false,
            firstNameText:"",
            lastName: false,
            lastNameText: "",
        };

        if (first === "" || first === undefined ) {
            errors.firstName = true;
            errors.firstNameText = "Please Provide a First Name";
        };

        if (last === "" || last === undefined ) {
            errors.lastName = true;
            errors.lastNameText = "Please Provide a Last Name";
        };

        if (email === "" || email === undefined ) {
            errors.email = true;
            errors.emailText = "Please Provide an email";
        };

        return errors;
    }

    const validateWithCode = (first, last, email, code) => {
        const errors = {
            email: false,
            emailText:"",
            firstName:false,
            firstNameText:"",
            lastName: false,
            lastNameText: "",
            code: false,
            codeText: "",
        };

        if (first === "" || first === undefined ) {
            errors.firstName = true;
            errors.firstNameText = "Please Provide a First Name";
        };

        if (last === "" || last === undefined ) {
            errors.lastName = true;
            errors.lastNameText = "Please Provide a Last Name";
        };

        if (email === "" || email === undefined ) {
            errors.email = true;
            errors.emailText = "Please Provide an email";
        };

        if (code === "" || code === undefined) {
            errors.code = true;
            errors.codeText = "Please provide the code from email"
        } else if (code.length !== 8) {
            errors.code = true;
            errors.codeText = "Code must be 8 characters long"
        }

        return errors;
    }

    const handleFist = (name) => {
        setFirst(name);
    };

    const handleEmail = (email) => {
        setEmail(email);
    };
    const handleLast = (last) => {
        setLast(last);
    };

    const handleCode = (code) => {
        setCode(code);
    };

    const handleAlreadyHaveCode = () => {
        // switch the hasCode state
        setHasCode(!hasCode);
    }
    // inputs and button on the landing "page" change if they already received a code
    let inputs;
    let button;
    // if they're re-entering their code, then add the code to the input box
    if (hasCode) {
        inputs = (
            <>
                <Typography variant="h5">
                Account Information
                </Typography>
                <TextField 
                    error={errState.email}
                    label="email"
                    InputLabelProps={{
                        shrink:true,
                    }}
                    sx={{ input: { bgcolor: '#ffffff' } }}
                    placeholder="example@example.com"
                    helperText={errState.emailText ? errState.emailText : ""}
                    value={email}
                    onChange={e => handleEmail(e.target.value)}
                />
                <TextField 
                    error={errState.firstName}
                    sx={{ input: { bgcolor: '#ffffff' } }}
                    label="First Name"
                    InputLabelProps={{
                        shrink:true,
                    }}
                    placeholder="Jane"
                    helperText={errState.firstNameText ? errState.firstNameText : ""}
                    value={first}
                    onChange={e => handleFist(e.target.value)}
                />
                <TextField 
                    error={errState.lastName}
                    sx={{ input: { bgcolor: '#ffffff' } }}
                    label="Last Name"
                    InputLabelProps={{
                        shrink:true,
                    }}
                    placeholder="Doe"
                    helperText={errState.lastNameText ? errState.lastNameText : ""}
                    value={last}
                    onChange={e => handleLast(e.target.value)}
                />
                  <TextField
                        error={errState.code}
                        label="Confirmation Code"
                        sx={{ input: { bgcolor: '#ffffff' } }}
                        InputLabelProps={{
                            shrink:true,
                        }}
                        placeholder="Code"
                        helperText={errState.code ? errState.codeText : ""}
                        value={code}
                        onChange={e => handleCode(e.target.value)}
                    />
                
                <Typography variant="subtitle1">
                    You'll receive an email with a link to create your password and finish creating your account.
                </Typography>
                <Button size='small' variant='text' color='info' width="100%" onClick={handleAlreadyHaveCode}>
                    Don't have a code
                </Button>
            </>
        );
        button = (
            <>
                 {
                        errState.submit ? (
                            <Typography
                            color= "red"
                            fontWeight={ 800 }
                            >
                                {errState.submitText}
                            </Typography>
                        ) : null
                    }
                   {
                    loading ? 
                        <CircularProgress /> 
                            : 
                        <Button
                            type="sumbit"
                            variant='contained'
                            color="success"
                            onClick={e => handleConfirmWithCode(e)}
                            disabled={errState.date}
                            width="10%"
                            size="large"
                        >
                            Confirm Account!
                        </Button>
                    }
            </>
        )
    } else {
        // normal inputs
        inputs = (
            <>
                <Typography variant="h5">
                Account Information
                </Typography>
                <TextField 
                    error={errState.email}
                    label="email"
                    InputLabelProps={{
                        shrink:true,
                    }}
                    sx={{ input: { bgcolor: '#ffffff' } }}
                    placeholder="example@example.com"
                    helperText={errState.emailText ? errState.emailText : ""}
                    value={email}
                    onChange={e => handleEmail(e.target.value)}
                />
                <TextField 
                    error={errState.firstName}
                    sx={{ input: { bgcolor: '#ffffff' } }}
                    label="First Name"
                    InputLabelProps={{
                        shrink:true,
                    }}
                    placeholder="Jane"
                    helperText={errState.firstNameText ? errState.firstNameText : ""}
                    value={first}
                    onChange={e => handleFist(e.target.value)}
                />
                <TextField 
                    error={errState.lastName}
                    sx={{ input: { bgcolor: '#ffffff' } }}
                    label="Last Name"
                    InputLabelProps={{
                        shrink:true,
                    }}
                    placeholder="Doe"
                    helperText={errState.lastNameText ? errState.lastNameText : ""}
                    value={last}
                    onChange={e => handleLast(e.target.value)}
                />
                <Typography variant="subtitle1">
                    You'll receive an email with a code to complete the registration process.
                </Typography>
                <Button size='small' variant='text' color='info' width="100%" onClick={handleAlreadyHaveCode}>
                    I already received a code 
                </Button>
            </>
        );
        button = (
            <>
                 {
                        errState.submit ? (
                            <Typography
                            color= "red"
                            fontWeight={ 800 }
                            >
                                {errState.submitText}
                            </Typography>
                        ) : null
                    }
                   {
                    loading ? 
                        <CircularProgress /> 
                            : 
                        <Button
                            type="sumbit"
                            variant='contained'
                            color="success"
                            onClick={e => handleClick(e)}
                            disabled={errState.date}
                            width="10%"
                            size="large"
                        >
                            Create Account!
                        </Button>
                    }
            </>
        );
    }

    let inner;
    if (!emailSent) {
        inner = (
            <Stack 
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
            >
                
            <Grid container spacing={2}>
            {/* left side "margin" */}
            <Grid item xs={1} md={2}></Grid>
            <Grid 
                item 
                xs={10}
                md={8}
            >
                <Stack
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                >
                <Paper
                    sx={{bgcolor:"#e8f0fa", padding:"1%"}}
                    elevation={5}
                    back
                >
                    <Stack spacing={1} display="flex" justifyContent="center" alignItems="center">
                       {inputs}
                    </Stack>
                </Paper>
                    {button}
                    <img src={foswecImage} style={{maxWidth:600}}/>
                 </Stack>
                 </Grid>
            <Grid item xs={1} md={2}></Grid>
        </Grid>
        </Stack>
        );
    } else {
        if (!codeVerified) {
            inner = (
                <Stack
                justifyContent="space-evenly"
                alignItems="center"
                spacing={1}
                >
                    <Typography variant='h4'>
                        Check your email for a code to verify your email! 
                    </Typography>
                    <Typography
                        variant="h6"
                    >
                        Email: {email} | Name: {first} {last}
                    </Typography>
                    <Typography variant="overline" fontWeight={600}>
                        Please check your junk/ spam folder if you're not seeing it in your inbox!
                    </Typography>
                    <TextField
                        error={errState.code}
                        label="Confirmation Code"
                        InputLabelProps={{
                            shrink:true,
                        }}
                        placeholder="Code"
                        helperText={errState.code ? errState.codeText : ""}
                        value={code}
                        onChange={e => handleCode(e.target.value)}
                    />
                    {loading ? <CircularProgress/> : <Button
                        type="sumbit"
                        variant='outlined'
                        onClick={e => handleConfirm(e)}
                    >
                        Confirm Account!
                    </Button>}
                    <Typography>
                        Once you enter the code, you'll receive one more email with a link to create your password.  This link will be to
                        fostwin.evergreeninnovations.co, where you'll use your provided email and created password to login.
                    </Typography>
                </Stack>
            )
        } else {
            inner = (
                <Stack
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={1}
                >
                    <Typography variant='h4'>
                        Check your email for a link to create your password.
                    </Typography>
                    <Typography variant="h5">
                        Please check your junk/ spam folder if you're not seeing it in your inbox!
                    </Typography>
                    <Typography variant='body1'>
                        You can exit this page once you've received the email to create your password.
                    </Typography>

                </Stack>
            );
        };
        
    };

    
    return (
        <>
            <Box sx={{display:"flex"}}>
                <AppBar
                // style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                    sx={{ marginBottom:'2%'}}
                    position="fixed"
                >
                    <Typography variant="h4" component="div">
                        Create FOSTWIN Account
                    </Typography>
                </AppBar>
                <Toolbar />
            </Box>
            <Box
                component="form"
                sx={{
                '& .MuiTextField-root': { m: 1, width: '90%' },
                '& .MuiDataGrid-root': { width: '90%' },
                // bgcolor:"#818699",
                }}
                noValidate
                autoComplete="off"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                
            {inner}

            </Box>
        </>
    );
}